import { AnimatePresence } from "framer-motion";
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import UseScrollToTop from "./hooks/useScrollToTop";
import ScrollToTop from "./components/ScrollToTop";
import AppFooter from "./components/shared/AppFooter";
import AppHeader from "./components/shared/AppHeader";
import "./css/App.css";
import 'react-toastify/dist/ReactToastify.css';
import useThemeSwitcher from "./hooks/useThemeSwitcher.jsx";

// const About = lazy(() => import('./pages/AboutMe'));
// const Contact = lazy(() => import('./pages/Contact.jsx'));
const Home = lazy(() => import('./pages/Home'));
// const Projects = lazy(() => import('./pages/Projects'));
const ProjectSingle = lazy(() => import('./pages/ProjectSingle.jsx'));


function App() {
	const [activeTheme, setTheme] = useThemeSwitcher();

	return (
		<AnimatePresence>
			<div className=" bg-secondary-light dark:bg-primary-dark transition duration-300">
				<Router>
					<ScrollToTop />
					<AppHeader activeTheme={activeTheme} setTheme={setTheme}/>
					<Suspense fallback={""}>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route
								path="projects/single-project"
								element={<ProjectSingle />}
							/>
						</Routes>
					</Suspense>
					<AppFooter activeTheme={activeTheme}/>
				</Router>
				<UseScrollToTop />
			</div>
			<ToastContainer />
		</AnimatePresence>
	);
}


export default App;

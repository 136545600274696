import React from 'react';

const Modal = ({
   id,
   button,
   header,
   body, footer,
   show: showProp,
   setShow: setShowProp,
   showModal: showModalProp,
   closeModal: closeModalProp
}) => {
    const [internalShow, setInternalShow] = React.useState(false);

    const show = showProp !== undefined ? showProp : internalShow;
    const setShow = setShowProp !== undefined ? setShowProp : setInternalShow;

    const showModal = showModalProp
        ? showModalProp
        : () => {
            document
                .getElementsByTagName("html")[0]
                .classList.add("overflow-y-hidden");
            setShow(true);
        };

    const closeModal = closeModalProp
        ? closeModalProp
        : () => {
            document
                .getElementsByTagName("html")[0]
                .classList.remove("overflow-y-hidden");
            setShow(false);
        };

    return (
        <>
            <button type="button" className="hover:underline " onClick={() => showModal()}>{button}</button>
            <div id={id} tabIndex="-1" aria-hidden={!show} aria-modal={show} role='dialog'
                 className={`${!show ? 'hidden' : ''} overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full  bg-black bg-opacity-50 backdrop-blur-md flex`}>
                <div className="relative p-4 w-full max-w-4xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {header &&
                            <div
                                className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    {header}
                                </h3>
                                <button type="button"
                                        onClick={() => closeModal()}
                                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                         fill="none"
                                         viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"
                                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>}
                        <div className="p-4 md:p-5 space-y-4 overflow-y-scroll" style={{maxHeight: "85vh"}}>
                            <p className="text-base  leading-relaxed text-gray-500 dark:text-white">
                                {body}
                            </p>
                        </div>
                        {footer &&
                            <div
                                className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:text-white">
                                {footer}
                            </div>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;
